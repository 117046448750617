<template>
  <div class="PageH" :class="bgc">
    <!-- <vue-particles
      v-if="bgc == 'newLoginPage'"
      color="#dedede"
      :particleOpacity="0.5"
      :particlesNumber="80"
      shapeType="edge"
      :particleSize="4"
      linesColor="#FFFFFF"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
      class="cash"
    >
    </vue-particles> -->

    <div class="ms-login">
      <div class="ms-title">智 能 客 控 平 台</div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="0px"
        class="ms-content"
      >
        <el-form-item prop="username">
          <el-input
            v-model="ruleForm.username"
            placeholder="username"
            clearable
          >
            <el-button slot="prepend" icon="el-icon-user-solid"></el-button>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            placeholder="password"
            v-model="ruleForm.password"
            show-password
            @keyup.enter.native="submitForm('ruleForm')"
          >
            <el-button slot="prepend" icon="el-icon-lock"></el-button>
          </el-input>
        </el-form-item>
        <div class="login-btn">
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
            :loading="loading"
            >登 录</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { login, getUserJurisdiction } from "@/api";
export default {
  data() {
    /* 自定义校验规则 */
    /* 密码自定义校验规则 */
    let Password = (rule, value, callback) => {
      // console.log(value)
      if (value == "") {
        callback(new Error("密码不能为空"));
      } else {
        // let passwordReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,10}$/
        let passwordReg = /^(?![^a-zA-Z]+$)(?!\D+$).{6,10}$/;
        if (passwordReg.test(value)) {
          callback();
        } else {
          callback(new Error("至少包含字母和数字，长度 6-10位"));
        }
      }
    };
    return {
      data: [],
      loading: false,
      ruleForm: {
        username: null,
        password: null,
        // username: 'wy11',
        // password: 'Qq123456'
        // username: 'admin2',
        // password: 'hq@1029',
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [
          // { required: true, validator: Password, trigger: ['blur', 'change'] }
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
      },
    };
  },

  computed: {
    archivalInformation: function () {
      return this.$store.state.copyright;
    },
    bgc() {
      return "login-wrap";
    },
    PlatformName: function () {
      return this.$store.state.PlatformName;
    },
  },
  methods: {
    submitForm: function (formName) {
      this.$refs[formName].validate((isOk) => {
        if (isOk) {
          this.loading = true;
          sessionStorage.clear();
          this.$store.commit("SET_TOKEN");
          login({
            username: this.ruleForm.username,
            password: this.ruleForm.password,
          })
            .then((res) => {
              // console.log(res);
              this.loading = false;
              if (res.data.code === "000000") {
                this.play();
                this.$message.success("登陆成功");
                // 将登录成功后的token保存到sessionStorage中
                this.$store.commit("SET_TOKEN", res.data.data.token);
                this.$store.commit("SAVE_USER", res.data.data.user);
                if (res.data.data.user.storeId) {
                  let data = {
                    brandId: res.data.data.user.brandId,
                    id: res.data.data.user.storeId,
                  };
                  this.$store.commit("SAVE_HOTEL", data);
                } else {
                  this.$store.commit("SAVE_HOTEL");
                }
                // 保存用户名信息
                // sessionStorage.setItem("user", JSON.stringify(res.data.data.user));
                // 保存用户名称
                sessionStorage.setItem(
                  "username",
                  JSON.stringify(res.data.data.user.username)
                );
                // 保存用户权限信息
                this.$store.commit("ROLE_MENU", res.data.data.permissions);

                // 路由跳转判断
                if (res.data.data.roles === "404") {
                  this.$message.warning("该账户未设置默认路由");
                  this.$router.push("/login");
                } else {
                  // console.log(res.data.data.roles);
                  if (
                    res.data.data.roles != null &&
                    res.data.data.roles != "null"
                  ) {
                    setTimeout(() => {
                      this.$router.push(res.data.data.roles);
                    }, 1000);
                  } else {
                    setTimeout(() => {
                      this.$router.push("/");
                    }, 1000);
                  }
                }

                // if (!res.data.data.roles.length) {
                //   this.$message.warning('该账户未设置默认路由')
                //   this.$router.push('/login')
                // } else {
                //   this.$router.push(res.data.data.roles[0])
                // }

                // this.getMenuList((_data)=>{
                //   console.log(_data)
                //   if(!_data){
                //     _data = "/";
                //   }
                //   this.$router.push(_data);
                // });
              } else {
                // 弹出错误提示信息，
                this.$message.error(res.data.message);
              }
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        } else {
          // alert('请正确输入信息后再提交')
          return false;
        }
      });
    },
    // 播放警报
    play() {
      let audio = document.getElementById("audio");
      audio.loop = true;
      audio.defaultPlaybackRate = 1.5;
      audio.muted = true;
      audio.load();
      audio.play();
      let t = setTimeout(() => {
        audio.pause();
        audio = null;
        clearTimeout(t);
        t = null;
      }, 200);
    },

    getMenuList: function (_callback) {
      let data = JSON.parse(sessionStorage.getItem("user"));
      let user = data.username;

      // 获取数据
      getUserJurisdiction(user).then((res) => {
        console.log(res.data.data[0].children);
        var _path = null;

        this.items = res.data[0].children;
        this.$store.commit("GET_MENU_LIST", this.items);
        if (res.data[0].children != false) {
          _path = this.getChildData(res.data[0].children);
        }
        _callback(_path);
      });
    },
    getChildData: function (data) {
      let _path = null;
      let obj = null;

      for (let key in data) {
        obj = data[key];

        if (obj.path != "/") {
          _path = obj.path;
          break;
        } else if (obj.children) {
          _path = this.getChildData(obj.children);

          if (_path) {
            break;
          }
        }
      }
      return _path;
    },
  },
  // beforeRouteLeave(to, from, next) {
  //  console.log(to);
  //   if (to.path == "kfjk") {
  //     if (!to.meta.keepAlive) {
  //       this.$destroy(); //销毁B的实例
  //       to.meta.keepAlive = true; //当我们进入到C时开启B的缓存
  //     }
  //     next();
  //   } else {
  //     this.$destroy(); //销毁B的实例
  //     next(); //当我们前进的不是C时我们让B页面刷新
  //   }
  // }
};
</script>

<style scoped lang="scss">
.login-wrap {
  position: relative;
  width: 100%;
  background-image: url(../../assets/img/login-bg.jpg);
  background-size: 100%;
}
.PageH {
  height: 100vh;
}
.ms-title {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid #ddd;
}
.ms-login {
  position: absolute;
  left: 50%;
  top: 45%;
  width: 350px;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  overflow: hidden;
}

.ms-foot {
  position: absolute;
  bottom: 6%;
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: #fff;

  a {
    color: #1890ff;
  }
}

.ms-content {
  padding: 30px 30px;
}
.login-btn {
  text-align: center;
}
.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}
.login-tips {
  font-size: 12px;
  line-height: 30px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
}
.newLoginPage {
  position: relative;
  width: 100%;
  background-image: url(../../assets/img/mapg.png);
  background-size: 100%;
  .ms-title {
    font-size: 24px;
    padding-top: 30px;
    color: #fff;
    border: none;
  }
  .ms-login {
    background: rgba(255, 255, 255, 0);
    box-shadow: 0px 2px 16px 0px rgba(255, 255, 255, 0.5);
    border-radius: 16px;
  }
}
</style>
